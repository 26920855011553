<template>
<div class="container border-container">
    <el-row class="info-container" type="flex" align="middle" justify="space-between">
        <div class="info-tabs">
            <span>学校账号</span>
        </div>
        <el-button type="primary" icon="el-icon-plus" @click="handleEditShow()">新增账号</el-button>
    </el-row>
    <div class="table-container">
        <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
            :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
            :cell-style="{height: '50px',padding: '5px 0'}" header-align="center"
            >
            <el-table-column prop="name" label="用户名" min-width="120" align="center">
                <template slot-scope="scope">
                    {{scope.row.user ? scope.row.user.name: '-'}}
                </template>
            </el-table-column>
            <el-table-column prop="phone" label="手机号" min-width="120" align="center">
                <template slot-scope="scope">
                    {{scope.row.user ? scope.row.user.phone: '-'}}
                </template>
            </el-table-column>
            <el-table-column prop="role" label="角色" min-width="120" align="center">
                <template slot-scope="scope">
                    {{scope.row.role_name || "-"}}
                </template>
            </el-table-column>
            <el-table-column prop="create_time" label="创建时间" min-width="120" align="center">
                <template slot-scope="scope">
                    {{scope.row.user ? timeFilter(scope.row.user.create_time): '-'}}
                </template>
            </el-table-column>
            <el-table-column label="操作" min-width="100" align="center" >
                <template slot-scope="scope">
                    <el-button type="text" class="icon-btn" @click="handleEditShow(scope.row)">
                        <el-tooltip placement="top" content="修改账号信息">
                            <i class="iconfont icon-edit" />
                        </el-tooltip>
                    </el-button>

                    <el-button type="text" class="icon-btn" @click="handlePasswordShow(scope.row)">
                        <el-tooltip placement="top" content="修改密码">
                            <i class="iconfont icon-lock" />
                        </el-tooltip>
                    </el-button>

                    <el-button type="text" class="icon-btn" @click="handleDelete(scope.row)">
                        <el-tooltip placement="top" content="删除">
                            <i class="iconfont icon-delete" />
                        </el-tooltip>
                    </el-button>
                </template>
            </el-table-column>
            <div class="" slot="empty">
                <no-data></no-data>
            </div>
        </el-table>
    </div>
    <div class="page-container">
        <el-pagination class="right" layout="total, sizes, prev, pager, next" background
            @size-change="pageSizeChange"
            @current-change="pageChange"
            :current-page="currentPage"
            :page-sizes="[total, 10, 15, 20, 25]"
            :total="total" :page-size="pageSize">
        </el-pagination>
    </div>
    <template class="dialog-container">
        <el-dialog :title="editTitle" :visible.sync="editVisible" class="school-account-dialog"
            width="600px" @close="handleEditClose"  :close-on-click-modal="editType === 'new' ? false : true">
            <div class="dialog-content">
                <div class="content-floor">
                    <span class="key">用户名</span>
                    <el-input class="value" v-model="editForm.name"  placeholder="请输入用户名"></el-input>
                </div>
                <div class="content-floor" v-if="editType === 'new'">
                    <span class="key" >手机号</span>
                    <el-input class="value" v-model="editForm.phone"  placeholder="请输入手机号"></el-input>
                </div>
                <div class="content-floor" v-if="editType === 'new'">
                    <span class="key">密码</span>
                    <el-input class="value" v-model="editForm.password"  placeholder="请输入密码"
                        type="password" :show-password='true'></el-input>
                </div>
                <div class="content-floor" style="height: 32px;">
                    <span class="key">学校管理员</span>
                    <el-radio-group class="value" v-model="editForm.type">
                        <el-radio :label="0">否</el-radio>
                        <el-radio :label="1">是</el-radio>
                    </el-radio-group>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handleEditAccount">保存</el-button>
                <el-button class="cancel" @click="editVisible = false">取消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改密码" :visible.sync="passwordVisible" class="school-account-dialog"
            width="500px" @closed="handlePasswordClose">
            <div class="dialog-content">
                <div class="content-floor">
                    <span class="key">密码</span>
                    <el-input class="value" v-model="passwordForm.newPass" placeholder="请输入新密码" type="password" show-password />
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handlePasswordChange">保存</el-button>
                <el-button class="cancel" @click="passwordVisible = false">取消</el-button>
            </div>
        </el-dialog>
    </template>
</div>
</template>

<script>
import Core from '@/core';
import { timeFilter } from '@/core/filters'
const USER_TYPE = Core.Const.USER_TYPE
export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {
        schoolId: {
            type: [String, Number]
        }
    },
    data() {
        return {
            USER_TYPE,
            userType: Core.Data.getUserType(),

            total: 0,
            currentPage: 1,
            pageSize: 10,

            tableData: [],

            editVisible: false,
            editType: '',
            editTitle: '',
            editForm: {
                id: 0,
                password: '',
                phone: '',
                role_id: '',
                type: '',
                name: '',
            },

            passwordVisible: false,
            passwordForm: {
                id: '',
                newPass: '',
            }
        };
    },
    computed: {},
    created() {},
    mounted() {
        this.getTableData()
    },
    methods: {
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },

        getTableData() {
            Core.Api.SchoolAccount.list(
                this.schoolId,
                this.currentPage,
                this.pageSize
            ).then(res => {
                console.log("getTableData -> res", res);
                this.total = res.count;
                this.tableData = res.list;
            });
        },

        // 新增、修改 账号
        handleEditShow(item) {
            if (item) {
                this.editType = 'edit';
                this.editTitle = '修改账号';
                this.editForm = {
                    id: item.id,
                    password: item.user.password,
                    phone: item.user.phone,
                    role_id: item.role_id || '',
                    type: item.type || '',
                    name: item.user.name,
                }
            } else {
                this.editType = 'new';
                this.editTitle = '新增账号';
            }
            this.editVisible = true;
        },
        handleEditClose() {
            this.editVisible = false;
            Object.assign(this.$data.editForm, this.$options.data().editForm)
        },
        handleEditAccount() { // 保存 / 修改 资源账号
            if(!this.editForm.name){
                return this.$message.error("请填写用户名")
            }
            if (this.editType === 'new') {
                if(!this.editForm.phone){
                    return this.$message.error("请填写手机号")
                }
                if(!(/^1[3456789]\d{9}$/.test(this.editForm.phone))){
                    return this.$message.error("手机号码有误，请正确填写")
                }
                if(!this.editForm.password){
                    return this.$message.error("请填写密码")
                }
            }
            Core.Api.SchoolAccount.save(
                this.editForm.id,
                this.editForm.password,
                this.editForm.phone,
                this.schoolId,
                this.editForm.role_id,
                this.editForm.type,
                this.editForm.name
            ).then((res) => {
                this.$message.success('保存成功');
                this.handleEditClose();
                this.getTableData()
            })
        },

        // 删除 账号
        handleDelete(item,type) {
            this.$confirm('确定要删除此账号吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.SchoolAccount.delete(item.id, item.user_id).then(res => {
                    this.$message.success('删除成功!');
                    this.pageChange(1)
                }).catch(err => {
                    console.log("handleDelete -> err", err);
                });
            });
        },

        // 修改 账号 密码
        handlePasswordShow(item) {
            this.passwordForm.id = item.id
            this.passwordVisible = true
        },
        handlePasswordClose() { // 取消修改密码
            this.passwordVisible = false
            this.passwordForm = {
                id: '',
                newPass: '',
            }
        },
        handlePasswordChange() { // 确定修改密码
            if (this.passwordForm.newPass === '') {
                return this.$message.warning('密码不能为空！')
            }
            Core.Api.SchoolAccount.passwordUpdate(
                this.passwordForm.id,
                this.passwordForm.newPass,
            ).then(res => {
                this.$message.success('修改成功')
                this.handlePasswordClose();
                this.getTableData();
            })
        },
        timeFilter
    },
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .info-container{
        @include flex(row, space-between, center);
        font-size: $font-size-md;
        font-weight: 500;
        color: $color-black;
        line-height: 22px;
        border-bottom: 1px solid rgba(230,234,238,1);
    }
}
.school-account-dialog {
    .dialog-content {
        .content-floor {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0px;
            }
            .key {
                display: inline-block;
                width: 100px;
            }
            .value {
                width: calc(100% - 100px);
            }
        }
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
}
</style>
